@import "src/styles/helpers";

.videoWrapper {
    position: relative;
    height: 100%;

    .previewWrapper {
      height: 100%;

      .previewOverlay {
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%);

        .playIcon {
          width: 92px;
          height: 92px;
        }

        .videoDuration {
          position: absolute;
          background: var(--secondary-900);
          border-radius: 100px;
          padding: 8px 16px;
          color: var(--primary-50);
          bottom: 16px;
        }
      }
    }
  
    &.default-view {
      margin: auto;
      aspect-ratio: unset;
      gap: 0;
      justify-content: center;
  
      @include media(landscape) {
        display: flex;

      }
  
      @include media(desktopAll) {
        display: flex;
      }
  
      img {
        position: static !important;
      }
  
      .preview {
        aspect-ratio: 3 / 2;
        object-fit: cover;
      }
  
      button {
        width: 100%;
  
        .author {
          display: none;
        }
      }
  
      .video {
        width: 100%;
        height: 100%;
        aspect-ratio: 3 / 2;

        @include media(portrait) {
          aspect-ratio: unset;
        }
  
        @include media(landscape) {
          height: 100%;
        }
  
        @include media(desktopAll) {
          height: 100%;
        }
      }
    }
  }

  .previewButton {
    flex: 1 0 0;
    height: 100%;
  }
  
  .video {
    height: 100%;
    flex: 1 0 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
