@import "src/styles/helpers";

.customProcessSection {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;

  &__titleAndDescriptionPart {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__title {
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
          font-family: "KharkivTone", sans-serif;
          font-size: 28px;
          font-weight: 400;
          line-height: 36px;

          mark {
              @include setProperty(color, var(--primary-500), var(--primary-900));
          }
      }

      &__description {
          @include setProperty(color, var(--secondary-50), var(--secondary-500));
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
      }
  }

  @include media(mobile) {
      &__titleAndDescriptionPart {
          &__title {
            max-width: 370px;
          }
      }
  }

  @include media(portrait) {
      padding-top: 88px;
      padding-bottom: 88px;

      &__titleAndDescriptionPart {
          gap: 32px;

          &__title {
            width: 370px;
            min-width: 370px;
        }
      }
  }


  @include media(landscape) {
      padding-top: 88px;
      padding-bottom: 88px;

      &__titleAndDescriptionPart {
          flex-direction: row;
          gap: 120px;

          &__title {
              width: 317px;
              min-width: 317px;
          }
      }
  }

  @include media(desktopAll) {
      padding-top: 120px;
      padding-bottom: 120px;

      &__titleAndDescriptionPart {
          flex-direction: row;
          gap: 120px;

          &__title {
              width: 376px;
              min-width: 376px;
              font-size: 32px;
              font-weight: 400;
              line-height: 40px;
          }

          &__description {
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: 0.183px;
          }
      }
  }

  @include media(desktopBig) {
      &__titleAndDescriptionPart {
          &__title {
              width: 616px;
              min-width: 616px;
          }
      }
  }
}
