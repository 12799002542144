@import 'src/styles/helpers';

.track {
  &_overflow {
    @include media(notMobile) {
      overflow: visible;
    }

    @include media(portrait) {
      overflow: hidden;
    }
  }
}

.slide {
  @include media(portrait) {
    min-width: 640px;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 44%;
  transform: translateY(-50%);
  width: 100%;

  &__arrow {
    background: none;
    border: none;
    cursor: pointer;
    height: unset;
    width: unset;

    &:disabled {
      display: none;
    }

    &_prev {
      transform: scale(-1, 1);
      left: -8px;
    }

    &_next {
      right: -8px;
    }

    & img {
      width: 136px !important;
      height: 94px !important;
      top: unset !important;
      bottom: unset !important;
    }
  }

  @include media(portrait) {
    &__arrow {
      &_prev {
        left: 75px;
      }
  
      &_next {
        right: 75px;
      }
    }
  }

  @include media(landscape) {
    &__arrow {
      &_prev {
        left: 64px;
      }
  
      &_next {
        right: 64px;
      }
    }
  }

  @include media(desktopSmall) {
    &__arrow {
      &_prev {
        left: 23px;
      }
  
      &_next {
        right: 23px;
      }
    }
  }
}

.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px;
  margin-top: 42px;
  bottom: 0 !important;
  padding: 0 !important;
  position: static !important;

  & > li {
    height: 16px;
  }

  &__item {
    @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;

    &:global {

      &.is-active {
        transform: none !important;
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }
  }
}
